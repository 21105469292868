import React, { useRef } from 'react';

import { connect } from '@nettbil/react-state';
import { Banner } from '@nettbil/react-components';
import { useIsSticky, useNetworkStatus } from '@nettbil/react-hooks';
import { networkStatus } from '@nettbil/constants';
import SVG from 'react-inlinesvg';
import modals from 'constants/modals';
import { cdnUrl } from 'config/config.js';

const ConnectionErrorBanner = ({ modal, networkConnection }) => {
  const ref = useRef(null);
  const isSticky = useIsSticky(ref);
  const connectionStatus = useNetworkStatus({ networkConnection });

  if (modal.name === modals.sessionSuspendModal) {
    return null;
  }

  switch (connectionStatus) {
    case networkStatus.offline:
      return (
        <div className={isSticky ? 'c-error-banner--sticky' : ''} ref={ref}>
          <Banner
            Icon={<SVG uniquifyIDs src={`${cdnUrl}/assets/icons/no-connection.svg`} />}
            color="offline"
            subtitle="Du er ikke tilkoblet internet. Vi prøver å gjenoprette tilkoblingen."
            bannerClass="c-error-banner"
          />
        </div>
      );
    case networkStatus.unstable:
      return (
        <div className={isSticky ? 'c-error-banner--sticky' : ''} ref={ref}>
          <Banner
            Icon={<SVG uniquifyIDs src={`${cdnUrl}/assets/icons/unstable-connection.svg`} />}
            color="unstable"
            subtitle="Tilkoblingen er ustabil. Vent en liten stund. Du kan gi bud igjen når denne beskjeden forsvinner."
            bannerClass="c-error-banner"
          />
        </div>
      );
    default:
      return null;
  }
};

export default connect((state) => ({
  modal: state.item.modal,
  networkConnection: state.item.networkConnection,
}))(ConnectionErrorBanner);
