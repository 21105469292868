import { apiBosonUrl, oauthAuthorizationHeader } from 'config/config.js';
import { DAO } from '@nettbil/react-dao';

function ShortUrl() {
  const dao = DAO('short-url', apiBosonUrl, null, oauthAuthorizationHeader);

  const custom = {
    resolve(hash) {
      const urlPostfix = `/resolve?hash=${hash}`;
      return dao.list(null, urlPostfix);
    },
  };

  return {
    ...custom,
  };
}

export default ShortUrl();
