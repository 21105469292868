import { persistState } from '@nettbil/react-state';

const persistedCarFilter = persistState.get({ key: 'carFilter', isSession: true, parse: true }) || {};

// NOTE: don't use more than one nesting level in item state
const initialState = {
  item: {
    auth: null,
    notification: { show: null, type: null, message: null },
    modal: { name: null },
    mobileNav: { active: false },
    networkConnection: {
      online: window.navigator.onLine,
      offlineAt: null,
      offlineOrigin: '',
      eventSourceFailure: false,
      eventSourceFailureAt: null,
      timerInSync: true,
      timerNotInSyncAt: null,
    },
    carCounts: {
      dealerCarsToBuyNowTotal: null,
      dealerCarsToBuyLaterTotal: null,
      dealerCarsWithBids: null,
      dealerCarsLiked: null,
      dealerCarsWon: null,
    },
    carCountsForOperations: {
      carsAlmostOnAuction: null,
      carsOnAuction: null,
      soldCount: null,
      notSoldCount: null,
      archiveCount: null,
      deleteCount: null,
    },
    carFilter: {
      yearFrom: persistedCarFilter.yearFrom || null,
      yearTo: persistedCarFilter.yearTo || null,
      mileageFrom: persistedCarFilter.mileageFrom || null,
      mileageTo: persistedCarFilter.mileageTo || null,
      brands: persistedCarFilter.brands || [],
      transmissionTypes: persistedCarFilter.transmissionTypes || [],
      wheelDriveTypes: persistedCarFilter.wheelDriveTypes || [],
      fuelTypes: persistedCarFilter.fuelTypes || [],
    },
    carFilterOverlay: {
      show: false,
    },
  },
};

export default initialState;
