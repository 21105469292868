import { DAO } from '@nettbil/react-dao';
import { oauthAuthorizationHeader, tokenRefreshUrl, uploadApiUrl } from 'config/config.js';

function Upload() {
  const dao = DAO('Uploads', uploadApiUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {
    getThumbnailUrlForCar(imagePaths) {
      let resourceUrl = '';
      try {
        if (Array.isArray(imagePaths)) {
          resourceUrl = imagePaths[0];
        } else if (imagePaths) {
          const _imagePaths = JSON.parse(imagePaths);
          if (Array.isArray(_imagePaths)) {
            resourceUrl = _imagePaths[0];
          }
        }
      } catch (err) {
        console.error('parsing thumb url', err, imagePaths);
      }
      if (resourceUrl) {
        if (
          resourceUrl.match(/https:\/\/(nettbil-)?images(-)?/) ||
          resourceUrl.match(/https:\/\/cdn.nettbil.no/) ||
          resourceUrl.match(/https:\/\/st4gcdn.skybil.no/)
        ) {
          const str = resourceUrl;
          const replacement = '.thumb.';
          return str.replace(/.([^.]*)$/, `${replacement}$1`);
        }
        return resourceUrl;
      } else {
        return resourceUrl;
      }
    },
    getThumbnailUrl(resourceUrl) {
      if (resourceUrl) {
        if (
          resourceUrl.match(/https:\/\/(nettbil-)?images(-)?/) ||
          resourceUrl.match(/https:\/\/cdn.nettbil.no/) ||
          resourceUrl.match(/https:\/\/st4gcdn.skybil.no/)
        ) {
          const str = resourceUrl;
          const replacement = '.thumb.';
          return str.replace(/.([^.]*)$/, `${replacement}$1`);
        }
        return resourceUrl;
      } else {
        return resourceUrl;
      }
    },

    getDisplayUrl(resourceUrl) {
      if (resourceUrl) {
        if (
          resourceUrl.match(/https:\/\/(nettbil-)?images(-)?/) ||
          resourceUrl.match(/https:\/\/cdn.nettbil.no/) ||
          resourceUrl.match(/https:\/\/st4gcdn.skybil.no/)
        ) {
          const str = resourceUrl;
          const replacement = '.display.';
          return str.replace(/.([^.]*)$/, `${replacement}$1`);
        }
        return resourceUrl;
      } else {
        return resourceUrl;
      }
    },
  };

  return {
    ...dao,
    ...custom,
  };
}

export default Upload();
