import actionTypes from './actionTypes';

// generic action for state.item
const setItem = (name, payload, persist) => ({
  type: actionTypes.setItem,
  name,
  payload,
  persist,
});

const unsetItem = (name, persist) => ({
  type: actionTypes.unsetItem,
  name,
  persist,
});

// modal
const showModal = (name) => setItem('modal', { name });
const hideModal = () => setItem('modal', { name: null });

// notification
const showNotification = (type, message, autocloseMs = 4000, scrollToTop = false) =>
  setItem('notification', {
    show: true,
    type,
    message,
    autocloseMs,
    scrollToTop,
  });

const hideNotification = () => setItem('notification', { show: false });

// car filters
const persistCarFilter = (carFilter) => setItem('carFilter', carFilter, { isSession: true, stringify: true });
const resetCarFilter = () =>
  setItem(
    'carFilter',
    {
      yearFrom: null,
      yearTo: null,
      mileageFrom: null,
      mileageTo: null,
      brands: [],
      transmissionTypes: [],
      wheelDriveTypes: [],
      fuelTypes: [],
    },
    { isSession: true, stringify: true }
  );

const showCarFilterOverlay = () => setItem('carFilterOverlay', { show: true });
const hideCarFilterOverlay = () => setItem('carFilterOverlay', { show: false });

// car counts
const setCarCounts = (carCounts) => setItem('carCounts', carCounts);
const setCarCountsForOperations = (carCountsForOperations) => setItem('carCountsForOperations', carCountsForOperations);

// network connection
const setNetworkConnection = (payload) => setItem('networkConnection', payload);

// mobile nav
const showMobileNav = () => setItem('mobileNav', { active: true });
const closeMobileNav = () => setItem('mobileNav', { active: false });

// refresh list
const refreshList = (name) => setItem('refreshList', { [name]: true });
const resetRefreshList = (name) => setItem('refreshList', { [name]: null });

// auth
const setAuth = (authInfo) => setItem('auth', authInfo);
const removeAuth = () => unsetItem('auth');

export {
  setItem,
  // modal
  showModal,
  hideModal,
  // notification
  showNotification,
  hideNotification,
  // car filters
  persistCarFilter,
  resetCarFilter,
  // car filter overlay
  showCarFilterOverlay,
  hideCarFilterOverlay,
  // car counts
  setCarCounts,
  setCarCountsForOperations,
  // network connection
  setNetworkConnection,
  // mobile nav
  showMobileNav,
  closeMobileNav,
  // refresh list
  refreshList,
  resetRefreshList,
  // auth,
  setAuth,
  removeAuth,
};
