import { DAO } from '@nettbil/react-dao';
import { toSearchParams } from '@nettbil/r2-d2';
import { apiBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';
import setFiltersToSearchParams from 'utils/setFiltersToSearchParams';
import removeEmptyValues from 'utils/removeEmptyValues';
import cleanupFilter from 'utils/cleanupFilter';

function Car() {
  const _dao = DAO('car', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  return {
    getAllBrandsAndModels() {
      return _dao.list(null, '/brands-and-models');
    },

    async getCarAndInformation(carId) {
      return _dao.list(carId, `/with-additional-information`);
    },

    async getForBuying(page = 1, pageSize = 10, filter = {}) {
      const _filter = { ...filter };
      cleanupFilter(_filter);
      const searchParams = toSearchParams({ page, pageSize, ..._filter });
      const result = await _dao.list(null, `/dealer/on-auction?${searchParams.toString()}`, true);

      return {
        list: result.data,
        paginator: {
          page: result.currentPage,
          perPage: result.pageSize,
          total: result.totalRecords,
          lastPage: result.totalPages,
        },
      };
    },

    getForBuyingCount() {
      return _dao.list(null, '/dealer/on-auction/count', true).then((response) => response.dealerCarsOnAuctionCount);
    },

    getAwaitingResponseWonCars(page = 1) {
      return _dao.list(null, `/dealer/won-cars-awaiting-response`, true);
    },

    async getForBuyingLater(page = 1, perPage = 10, filter) {
      const _filter = { page, perPage, ...filter };

      cleanupFilter(_filter);
      let searchParams = toSearchParams(_filter);

      const result = await _dao.list(null, `/for-buying-later?${searchParams.toString()}`, true);

      return {
        list: result.data,
        paginator: {
          page: result.currentPage,
          perPage: result.pageSize,
          total: result.totalRecords,
          lastPage: result.totalPages,
        },
      };
    },

    getForBuyingLaterCount(filter = {}) {
      let searchParams = new URLSearchParams({});

      searchParams = setFiltersToSearchParams(searchParams, filter);

      return _dao.list(null, `/count/for-buying-later?${searchParams.toString()}`, true);
    },

    getWithActiveBids() {
      return _dao.list(null, '/dealer/with-active-bids');
    },

    async getWithActiveBidsCount() {
      const { count } = await _dao.list(null, '/dealer/with-active-bids/count');
      return count;
    },

    async getFavourites(page = 1, pageSize = 10) {
      const searchParams = toSearchParams({ page, pageSize });
      const result = await _dao.list(null, `/dealer/favourite-cars?${searchParams.toString()}`, true);

      return {
        list: result.data,
        paginator: {
          page: result.currentPage,
          perPage: result.pageSize,
          total: result.totalRecords,
          lastPage: result.totalPages,
        },
      };
    },

    getFavouritesCount() {
      return _dao.list(null, '/dealer/favourite-cars/count', true).then((result) => result.dealerFavouriteCarsCount);
    },
    async getFilterCounts(type = 'auction', filter = {}) {
      const _filter = { ...filter };

      cleanupFilter(_filter);
      let searchParams = toSearchParams(_filter);

      return _dao.list(
        null,
        `/dealer/${type === 'upcoming' ? 'upcoming' : 'auction/lot'}/filter/count?${searchParams}`,
        true
      );
    },
    async getDealerWonCars(page = 1, pageSize = 10, filter, searchString, nextPage) {
      let options = {
        page,
        pageSize,
        searchValue: searchString || '',
      };

      const _filter = { ...filter };

      cleanupFilter(_filter);

      if (_filter) {
        options = {
          ...options,
          ...removeEmptyValues(_filter),
        };
      }

      const queryParams = nextPage || `?${toSearchParams(options)}`;
      const result = await _dao.list(null, `/dealer/won-cars${queryParams}`);

      return {
        list: result.data,
        paginator: {
          page: result.currentPage,
          total: result.totalRecords,
          lastPage: result.totalPages,
        },
      };
    },

    async getDealerContract(carId) {
      return _dao.list(carId, '/dealer/contract');
    },

    async getDealerWonCarsCount() {
      const result = await _dao.list(null, '/dealer/won-cars/count');

      return result.count;
    },

    confirmAsPickedUp(carId) {
      let urlPostfix = '/confirm-as-picked-up-by-dealer';
      return _dao.save({ carId }, urlPostfix);
    },

    async like(carId) {
      return _dao.save({ id: carId }, '/like');
    },

    async unlike(carId) {
      return _dao.remove(carId, '/like');
    },

    dealerGetRelatedTransport(carId) {
      let urlPostfix = `${carId}/dealer/car-transport`;
      return _dao.list(urlPostfix);
    },
    dealerGetInspectionReport(carId) {
      let urlPostfix = `${carId}/dealer/inspection-report/view`;
      return _dao.list(urlPostfix);
    },

    async getNumberPlate(carId) {
      const result = await _dao.list(null, `/dealer/number-plate/${carId}`);
      return result.numberPlate;
    },

    async getVinNumber(carId) {
      const result = await _dao.list(null, `/dealer/vin-number/${carId}`);
      return result.vinNumber;
    },
  };
}

export default Car();
