import { apiBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';
import { DAO } from '@nettbil/react-dao';

function CarSearches() {
  const dao = DAO('car-search', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {
    getSavedSearches() {
      return dao.list(null, '/dealer');
    },
    getSavedSearchById(id) {
      return dao.list(id, '/dealer');
    },
    saveCarSearch(search) {
      return dao.save(search, '/dealer');
    },
    deleteSearch(id) {
      return dao.remove(id, '/dealer');
    },
  };

  return {
    ...dao,
    ...custom,
  };
}

export default CarSearches();
