import { userTypes } from '@nettbil/constants';
import { ga } from '@nettbil/react-analytics';

const mapUserType = {
  [userTypes.OPERATIONS]: 'internal',
  [userTypes.ADMIN]: 'internal',
  [userTypes.CUSTOMER_ADVISOR]: 'internal',
  [userTypes.DEALER]: 'carDealer',
  [userTypes.CLIENT]: 'client',
};

const userEvent = (userID, clientID, userType) => ({
  userID: userID || '',
  clientID: clientID || ga.gaGetClientId() || '',
  userType: mapUserType[userType] || '',
});

export default userEvent;
