import { auctionBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';

import { DAO } from '@nettbil/react-dao';

function Auction() {
  const auctionDao = DAO('', auctionBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  return {
    attemptBidPlacement(bid) {
      return auctionDao.save({ bid }, 'attempt-bid-placement');
    },
    setAutoBid(autoBidItem) {
      if (autoBidItem.id) {
        const id = autoBidItem.id;
        delete autoBidItem.id;
        return auctionDao.save(autoBidItem, `update-auto-bid/${id}`);
      } else {
        return auctionDao.save(autoBidItem, 'set-auto-bid');
      }
    },
    removeAutoBid(autoBidId) {
      return auctionDao.remove(null, `remove-auto-bid/${autoBidId}`);
    },
    async getAutoBid(carId) {
      const autoBid = await auctionDao.list(null, `get-auto-bid?carId=${carId}`);
      return Object.values(autoBid).length === 0 ? null : autoBid;
    },
  };
}

export default Auction();
