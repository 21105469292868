import { DAO } from '@nettbil/react-dao';
import { apiBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';
import { toSearchParams } from '@nettbil/r2-d2';

function CarTransport() {
  const dao = DAO('car-transport', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {
    async status(id) {
      const urlPostfix = '/status';

      return dao.list(id, urlPostfix);
    },
    updatePriceEstimate(carId, alteredAddress) {
      const data = {
        carId,
        alteredAddress,
      };

      const urlPostfix = '/update-price';
      return dao.save(data, urlPostfix);
    },
    dealerRequestCarTransport(carId, comment) {
      const data = {
        carId,
        comment,
      };

      const urlPostfix = '/dealer-request';
      return dao.save(data, urlPostfix);
    },
    list(page, pageSize, searchString, nextPage) {
      const options = {
        page,
        pageSize,
        search: {
          value: searchString,
          fields: ['car-numberPlate'],
        },
        orderBy: { field: 'id', direction: 'desc' },
        filter: { isRequested: true },
        join: ['car-inspectionCenter'],
        distinct: 'id',
        fields: [
          'id',
          'carId',
          'dealerId',
          'pickupAddress',
          'deliveryAddress',
          'contactName',
          'contactEmail',
          'contactTelephoneNumber',
          'additionalInformation',
          'price',
          'error',
          'isRequested',
          'isOrdered',
          'consignmentId',
          'eta',
          'createdAt',
          'updatedAt',
          'car-numberPlate',
          'car-inspectionCenter-name',
        ],
      };
      const queryParams = nextPage || `?${toSearchParams(options)}`;
      return dao.list(null, queryParams);
    },
  };

  return {
    ...dao,
    ...custom,
  };
}

export default CarTransport();
