import { apiBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';
import { DAO } from '@nettbil/react-dao';

function CarReminder() {
  const dao = DAO('car-reminder', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {
    add(carId) {
      return dao.save({ carId }, '/add', true);
    },
  };
  return {
    ...custom,
  };
}
export default CarReminder();
