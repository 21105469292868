import { DAO } from '@nettbil/react-dao';

import {
  apiBosonUrl,
  googleRecaptchaSiteKeyCheckboxChallenge,
  googleRecaptchaSiteKeyInvisible,
  oauthAuthorizationHeader,
  passwordChangeAfterResetRecaptchaAction,
  requestPasswordResetRecaptchaAction,
  tokenRefreshUrl,
} from 'config/config.js';
import { invisibleGoogleRecaptcha } from '@nettbil/react-utils';

function Auth() {
  const isAuthenticatedDao = DAO('is-authenticated', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const meDao = DAO('me', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const requestPasswordResetDao = DAO('request-password-reset', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const setNewPasswordDao = DAO('set-new-password', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const loginDao = DAO('login', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const logoutDao = DAO('logout', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);
  const authorizeDao = DAO('authorize', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {
    isAuthenticated() {
      return isAuthenticatedDao.list(null, '');
    },

    getLoggedInUserInformation() {
      return meDao.list(null, '');
    },

    attemptTelephoneNumberVerification(telephoneNumber, retry) {
      return meDao.save({ telephoneNumber, retry }, '/attempt-verification/sms');
    },

    submitTelephoneNumberVerificationCode(otp, uuid) {
      return meDao.save({ otp, uuid }, '/submit-verification-code/sms');
    },

    attemptEmailVerification(email, retry) {
      return meDao.save({ email, retry }, '/attempt-verification/email');
    },

    submitEmailVerificationCode(otp, uuid) {
      return meDao.save({ otp, uuid }, '/submit-verification-code/email');
    },

    saveMe(content) {
      return meDao.save(content);
    },

    getLoginAttempt() {
      let loginAttempt;
      try {
        loginAttempt = JSON.parse(sessionStorage.getItem('loginAttempt'));
      } catch (_) {
        loginAttempt = null;
      }
      return loginAttempt;
    },

    setLoginAttempt(attempt) {
      sessionStorage.setItem('loginAttempt', JSON.stringify(attempt));
    },

    removeLoginAttempt() {
      sessionStorage.removeItem('loginAttempt');
    },

    getOtpChallenge() {
      let challenge;
      try {
        challenge = JSON.parse(sessionStorage.getItem('otp'));
      } catch (_) {
        challenge = null;
      }
      return challenge;
    },

    setOtpChallenge(challenge) {
      sessionStorage.setItem('otp', JSON.stringify(challenge));
    },

    removeOtpChallenge() {
      sessionStorage.removeItem('otp');
    },

    async handleLocalStrategyLogin(strategy, options, recaptchaResponseCheckboxChallenge) {
      const payload = {};

      switch (strategy) {
        case 'username':
          payload.username = options.username;
          payload.password = options.password;
          break;
        case 'otp':
          payload.username = options.username;
          payload.challenge = { answer: options.otp, uuid: options.uuid };
          break;
        case 'email':
          payload.username = options.username;
          payload.challenge = { uuid: options.uuid };
          payload.channel = 'EMAIL';
          break;
        default:
          throw new Error(`Unsupported strategy ${strategy}`);
      }

      try {
        await this.authorize();
        const { challenge } = await this.oAuthLogin(payload, recaptchaResponseCheckboxChallenge);

        if (challenge) return { challenge };
        const currentUser = await this.getLoggedInUserInformation();
        if (['dealer'].includes(currentUser.userType)) {
          return currentUser;
        }
        throw new Error('You are not authorized to complete this action');
      } catch (error) {
        console.error('oauth:error', error);
        throw error;
      }
    },

    async handleLogout() {
      try {
        await this.oAuthLogout();
      } catch (_) {}
    },

    handleResetPassword(email, recaptchaResponseCheckboxChallenge) {
      if (recaptchaResponseCheckboxChallenge) {
        return requestPasswordResetDao.save({
          email,
          grr: recaptchaResponseCheckboxChallenge,
          grsk: googleRecaptchaSiteKeyCheckboxChallenge,
          gra: requestPasswordResetRecaptchaAction,
        });
      }

      return invisibleGoogleRecaptcha(
        (recaptcha) =>
          requestPasswordResetDao.save({
            email,
            grr: recaptcha.grr,
            grsk: googleRecaptchaSiteKeyInvisible,
            gra: requestPasswordResetRecaptchaAction,
          }),
        {
          action: requestPasswordResetRecaptchaAction,
          siteKey: googleRecaptchaSiteKeyInvisible,
        }
      );
    },

    setNewPassword(newPassword, uuid, otp, email, recaptchaResponseCheckboxChallenge) {
      if (recaptchaResponseCheckboxChallenge) {
        return setNewPasswordDao.save({
          newPassword,
          uuid,
          otp,
          email,
          grr: recaptchaResponseCheckboxChallenge,
          grsk: googleRecaptchaSiteKeyCheckboxChallenge,
          gra: passwordChangeAfterResetRecaptchaAction,
        });
      }

      return invisibleGoogleRecaptcha(
        (recaptcha) =>
          setNewPasswordDao.save({
            newPassword,
            uuid,
            otp,
            email,
            grr: recaptcha.grr,
            grsk: googleRecaptchaSiteKeyInvisible,
            gra: passwordChangeAfterResetRecaptchaAction,
          }),
        {
          action: passwordChangeAfterResetRecaptchaAction,
          siteKey: googleRecaptchaSiteKeyInvisible,
        }
      );
    },

    authorize() {
      return authorizeDao.head('?country=norway');
    },

    oAuthLogin(payload, recaptchaResponseCheckboxChallenge) {
      if (recaptchaResponseCheckboxChallenge) {
        return loginDao.save({
          ...payload,
          grr: recaptchaResponseCheckboxChallenge,
          grsk: googleRecaptchaSiteKeyCheckboxChallenge,
          gra: 'PORTAL_LOGIN_FORM',
          grant_type: 'password',
        });
      }

      return invisibleGoogleRecaptcha(
        (recaptcha) =>
          loginDao.save({
            ...payload,
            grant_type: 'password',
            grr: recaptcha.grr,
            grsk: googleRecaptchaSiteKeyInvisible,
            gra: 'PORTAL_LOGIN_FORM',
          }),
        {
          action: 'PORTAL_LOGIN_FORM',
          siteKey: googleRecaptchaSiteKeyInvisible,
        }
      );
    },

    oAuthLogout() {
      return logoutDao.save({});
    },
  };

  return {
    ...custom,
  };
}

export default Auth();
