import React, { useEffect, useRef } from 'react';

import { datadogLogs } from '@datadog/browser-logs';

// api, hooks
import { showModal } from 'state/actions';
import { connect } from '@nettbil/react-state';
import { Button } from '@nettbil/react-components';
import modals from 'constants/modals';

import eventSource from 'utils/eventSource';

const SessionSuspend = ({ modal, showModal, auth }) => {
  const tryingToSuspendSession = useRef();
  const blurStart = useRef();
  const focusResume = useRef();
  const suspendedSession = useRef(false);

  const resumeSession = () => {
    window.location.reload();
  };

  useEffect(() => {
    const suspendSession = () => {
      const serverSessionHash = sessionStorage.getItem('serverSessionHash');
      const instanceHashes = JSON.parse(localStorage.getItem('instanceHashes'));
      if (
        serverSessionHash &&
        instanceHashes &&
        instanceHashes.length > 3 &&
        !suspendedSession.current &&
        instanceHashes[0].split('|')[0] === serverSessionHash
      ) {
        suspendedSession.current = true;
        tryingToSuspendSession.current = false;

        showModal(modals.sessionSuspendModal);
        try {
          datadogLogs.logger.info('portal-session-suspended', {
            userId: auth?.id,
            userType: auth?.userType,
            userLocale: auth?.countryCode,
          });
        } catch (error) {
          console.error('>>>sessionsuspend: logger error', error);
        }
        window.sessionStorage.setItem('eventSourceTerminated', '1');
        eventSource.terminate();
      }
    };

    const tryToSuspendSession = () => {
      suspendSession();
      if (!suspendedSession.current && tryingToSuspendSession.current) {
        setTimeout(() => {
          tryToSuspendSession();
        }, 10000);
      }
    };

    const onBlur = () => {
      if (!tryingToSuspendSession.current) {
        tryingToSuspendSession.current = true;
        tryToSuspendSession();
      }

      blurStart.current = Date.now();
    };

    const onFocus = () => {
      tryingToSuspendSession.current = false;

      focusResume.current = Date.now();

      const timeBlurred = blurStart.current ? focusResume.current - blurStart.current : 0;

      if (timeBlurred > 60 * 1000) {
        window.location.reload();
      }
    };

    if (!suspendedSession.current && window.sessionStorage.getItem('eventSourceTerminated')) {
      window.sessionStorage.removeItem('eventSourceTerminated');
    }

    window.addEventListener('focus', onFocus, false);
    window.addEventListener('blur', onBlur, false);
    return () => {
      window.removeEventListener('focus', onFocus, false);
      window.removeEventListener('blur', onBlur, false);
    };
  }, [showModal, auth]);

  if (modal.name === modals.sessionSuspendModal) {
    return (
      <div className="page-blur" data-cy="session-suspend-modal">
        <p className="u-h3">Du kan ha maksimalt tre faner åpne samtidig</p>
        <p className="u-h5">Lukk en fane og trykk på knappen under for å laste siden på nytt</p>
        <br />
        <Button size="medium" color="primary" dataCy="resume-session-button" onClick={resumeSession}>
          Last siden på nytt
        </Button>
      </div>
    );
  }

  return null;
};

export default connect(
  (state) => ({
    modal: state.item.modal,
    auth: state.item.auth,
  }),
  { showModal }
)(SessionSuspend);
