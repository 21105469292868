import { userTypes } from '@nettbil/constants';

const getRedirectUrlForRole = (role) => {
  let redirectUrl = '';

  if (role === userTypes.DEALER) {
    redirectUrl = '/dashboard/dealer/cars-to-buy';
  }

  return redirectUrl;
};

export default getRedirectUrlForRole;
