import { apiBosonUrl, oauthAuthorizationHeader, tokenRefreshUrl } from 'config/config.js';

import { DAO } from '@nettbil/react-dao';

function InspectionCenter() {
  const dao = DAO('inspection-center', apiBosonUrl, tokenRefreshUrl, oauthAuthorizationHeader);

  const custom = {};

  return {
    ...dao,
    ...custom,
  };
}

export default InspectionCenter();
