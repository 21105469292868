import cleanupFilter from 'utils/cleanupFilter';

const setFiltersToSearchParams = (searchParams, filter) => {
  const _filter = { ...filter };

  cleanupFilter(_filter);

  Object.keys(_filter).forEach((key) => {
    if (_filter[key] !== null && _filter[key] !== '' && _filter[key] !== undefined) {
      searchParams.set(key, Array.isArray(_filter[key]) ? JSON.stringify(_filter[key]) : _filter[key]);
    }
  });

  return searchParams;
};

export default setFiltersToSearchParams;
