const connectionErrorEvent = (connectionErrorType, userId, userType, connectionErrorOrigin, countryCode) => ({
  event: 'ConnectionError',
  connectionErrorType,
  userId,
  userType,
  userLocale: countryCode,
  connectionErrorOrigin,
});

export default connectionErrorEvent;
