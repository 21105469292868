import { datadogLogs } from '@datadog/browser-logs';
import { datadogLogsEnabled, env } from 'config/config.js';

if (datadogLogsEnabled === 'enabled') {
  datadogLogs.init({
    site: 'datadoghq.com',
    clientToken: 'pube75184a7f6ed4c1e50c0ae5d5280f91d',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env,
    version: process.env.APP_VERSION,
    service: 'dealer-portal',
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
