import userEvent from './userEvent';
import connectionErrorEvent from './connectionErrorEvent';
import filterEvent from './filterEvent';

const events = {
  connectionErrorEvent,
  userEvent,
  filterEvent,
};

export default events;
