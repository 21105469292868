// React components
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Styles
import './style.pcss';
import './scss/main.scss';
import '@nettbil/react-design-system/style.css'; // eslint-disable-line import/no-unresolved

// state, context
import { AppStateProvider, notificationMiddleware, persistStateMiddleware } from '@nettbil/react-state';
import appReducer from 'state/appReducer';
import initialState from 'state/initialState';
import connectionErrorMiddleware from 'state/connectionErrorMiddleware';
import { hideNotification } from 'state/actions';

// component
import LayoutMain from 'components/layouts/LayoutMain';

window.React = React;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AppStateProvider
    reducer={appReducer}
    initialState={initialState}
    beforeMiddleware={[]}
    middleware={[persistStateMiddleware, notificationMiddleware(hideNotification), connectionErrorMiddleware]}
  >
    <BrowserRouter>
      <LayoutMain />
    </BrowserRouter>
  </AppStateProvider>
);

if (module.hot) {
  module.hot.accept();
}
