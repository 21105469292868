import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// services
import analytics from 'services/analytics';

// components
import SessionSuspend from 'components/common/SessionSuspend';
import ConnectionErrorBanner from 'components/common/ConnectionErrorBanner';
import getRedirectUrlForRole from 'utils/getRedirectUrlForRole';
import { ErrorBoundary, GenericSkeleton, NotificationBanner, TopNavSkeleton } from '@nettbil/react-components';
import { connect } from '@nettbil/react-state';

import { networkStatus, userTypes } from '@nettbil/constants';
import { ga } from '@nettbil/react-analytics';
import modals from 'constants/modals';
import { closeMobileNav, setAuth } from 'state/actions';
import { Auth } from 'data';
import eventSource from 'utils/eventSource';
import { datadogLogs } from '@datadog/browser-logs';
import DatadogInit from 'lib/datadog-init';

const Header = lazy(() => import('./Header'));
const SimpleHeader = lazy(() => import('./SimpleHeader'));
const PrivacyPolicy = lazy(() => import('components/pages/public/user/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('components/pages/public/user/CookiePolicy'));
const LayoutDashboard = lazy(() => import('./LayoutDashboard'));
const Logout = lazy(() => import('components/pages/public/Logout'));
const PageNotFound = lazy(() => import('components/pages/public/PageNotFound'));
const ShortUrlResolver = lazy(() => import('components/pages/public/ShortUrlResolver'));
const Login = lazy(() => import('components/pages/public/account/Login'));
const RequestPasswordReset = lazy(() => import('components/pages/public/account/RequestPasswordReset'));
const ResetPassword = lazy(() => import('components/pages/public/account/ResetPassword'));
const DealerPickupCode = lazy(() => import('components/pages/dealer/DealerPickupCode'));
const OtpChallenge = lazy(() => import('components/pages/public/account/OtpChallenge'));

const LayoutMain = ({ modal, mobileNav, closeMobileNav, auth, notification, setAuth }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      let currentUser;
      try {
        if (await Auth.isAuthenticated()) {
          currentUser = await Auth.getLoggedInUserInformation();
        }
      } catch (_) {
      } finally {
        if (currentUser && currentUser.userType === userTypes.DEALER) {
          setAuth(currentUser);
          await eventSource.reInitialize(true, 'LOGIN');
        } else if (
          location.pathname === '/' ||
          location.pathname.startsWith('/dashboard') ||
          location.pathname.startsWith('/dealer-pickup-code')
        ) {
          const redirectParams =
            location.pathname !== '/' ? `?redirect=${encodeURIComponent(location.pathname + location.search)}` : '';
          navigate(`/login${redirectParams}`, { replace: true });
        }
      }
    };
    if (!auth) {
      init();
    }
  }, [setAuth, location, auth, navigate]);

  useEffect(() => {
    analytics.init(() =>
      analytics.pushPersisted((data) =>
        data.forEach((d) => {
          datadogLogs.logger.info(`connection-error-${networkStatus.offline}`, {
            userId: d.userId,
            userType: d.userType,
            userLocale: auth?.countryCode,
            connectionErrorOrigin: d.connectionErrorOrigin,
          });
        })
      )
    );

    let analyticsUserId;
    let userType;
    const currentUser = auth;
    if (currentUser) {
      ({ analyticsUserId, userType } = currentUser);
    }
    const analyticsClientId = ga.gaGetClientId();
    const userEvent = analytics.events.userEvent(analyticsUserId, analyticsClientId, userType);
    analytics.push(userEvent);

    window.scrollTo(0, 0);
  }, [auth]);

  const RedirectPage = ({ auth }) => {
    useEffect(() => {
      if (auth?.userType === userTypes.DEALER) {
        navigate(getRedirectUrlForRole(auth?.userType), { replace: true });
      }
    }, [auth]);

    return <GenericSkeleton />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ErrorBoundary>
      <SessionSuspend />
      <DatadogInit />
      <div
        className={[
          'dashboard-wrapper',
          modal.name === modals.sessionSuspendModal ? 'blur' : '',
          mobileNav.active ? 'mobilenav-active' : '',
        ].join(' ')}
      >
        <Suspense fallback={<TopNavSkeleton />}>
          {auth ? <Header currentPage={location.pathname} auth={auth} /> : <SimpleHeader />}
        </Suspense>
        {!!mobileNav.active && (
          <div
            className="navbar-backdrop animated fadeIn"
            style={{ height: '100%', position: 'fixed', width: '100%' }}
            onClick={closeMobileNav}
          />
        )}
        <NotificationBanner notification={notification} />
        <ConnectionErrorBanner />
        <Suspense fallback={<GenericSkeleton />}>
          <Routes>
            <Route path="/" element={<RedirectPage auth={auth} />} />
            {/* User Login */}
            <Route path="/login" element={<Login />} />
            <Route path="/otp-challenge" element={<OtpChallenge />} />
            <Route path="/request-password-reset" element={<RequestPasswordReset />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/* Terms / Cookies / Privacy policies */}
            <Route path="/personvernerklaering" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiePolicy />} />
            {/* OTHER */}
            <Route path="/dealer-pickup-code" element={<DealerPickupCode />} />
            <Route path="/nt" element={<ShortUrlResolver />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/dashboard/*" element={<LayoutDashboard />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default connect(
  (state) => ({
    mobileNav: state.item.mobileNav,
    modal: state.item.modal,
    auth: state.item.auth,
    notification: state.item.notification,
  }),
  { closeMobileNav, setAuth }
)(LayoutMain);
