import { datadogLogs } from '@datadog/browser-logs';
import { networkStatus } from '@nettbil/constants';
import analytics from 'services/analytics';
import modals from 'constants/modals';

const connectionErrorMiddleware = (state = {}, action = {}) => {
  const { networkConnection, modal, auth } = state.item || {};
  if (modal && modal.name === modals.sessionSuspendModal) {
    return;
  }

  const { id: userId, userType, countryCode } = auth || {};

  const { online, offlineOrigin, eventSourceFailure, timerInSync } = networkConnection || {};

  if (!online) {
    console.debug('OFFLINE CONNECTION TRACKING', offlineOrigin);
    analytics.persist(
      analytics.events.connectionErrorEvent(networkStatus.offline, userId, userType, offlineOrigin, countryCode)
    );
  } else if (eventSourceFailure) {
    console.debug('UNSTABLE CONNECTION TRACKING eventSourceFailure');
    datadogLogs.logger.info(`connection-error-${networkStatus.unstable}`, {
      userId,
      userType,
      userLocale: auth?.countryCode,
      connectionErrorOrigin: 'eventSourceFailure',
    });
    analytics.push(
      analytics.events.connectionErrorEvent(
        networkStatus.unstable,
        userId,
        userType,
        'eventSourceFailure',
        auth?.countryCode
      )
    );
  } else if (!timerInSync) {
    console.debug('UNSTABLE CONNECTION TRACKING timerNotInSync');
    datadogLogs.logger.info(`connection-error-${networkStatus.unstable}`, {
      userId,
      userType,
      userLocale: auth?.countryCode,
      connectionErrorOrigin: 'timerNotInSync',
    });
    analytics.push(
      analytics.events.connectionErrorEvent(networkStatus.unstable, userId, userType, 'timerNotInSync', countryCode)
    );
  }
};

export default connectionErrorMiddleware;
