const environmentConfig = window._env_ || {};
console.log('>>>> IN CONFIG NODE_ENV IS', process.env.NODE_ENV);
console.log('>>>> CONFIG FROM ENV:', JSON.stringify(environmentConfig));

export const auction = {
  step: 1000,
  quickBidValues: [1000, 3000, 5000, 10000],
  quickBidValuesShort: [1000, 3000, 5000],
  maxValue: 100000000,
  messages: {
    genericError: `Ops, her har noe gått galt.`,
    errorNotInThousands: 'Tilbudet må være i flere tusen',
    dealerNotSelected: 'Velg forhandler for å legge inn bud',
    autoBidSuccess: (value) => `Autobud er mottatt og satt til ${value}`,
    autoBidErrorMax: (value) => `Autobud på ${value} er for høyt`,
    bidErrorMin: (value) => `Budet må være minst ${value}`,
    bidErrorMax: (value) => `Budet på ${value} er for høyt`,
    bidSuccess: (value) => `Bud på ${value} er mottatt`,
  },
};

export const auctionBosonUrl = environmentConfig.AUCTION_BOSON_URL;
export const eventSourcePath = environmentConfig.EVENT_SOURCE_PATH;
export const apiBosonUrl = environmentConfig.API_BOSON_URL;
export const tokenRefreshUrl = `${environmentConfig.API_BOSON_URL}/login`;
export const uploadApiUrl = environmentConfig.UPLOAD_API_URL;
export const norwayClientUrl = environmentConfig.NORWAY_CLIENT_URL;
export const norwaySellerUrl = environmentConfig.NORWAY_SELLER_URL;
export const oauthAuthorizationHeader = 'Basic aVA2Q05sNE5ydXZBLVNILVpBdzU0LnBvcnRhbDo=';
export const env = process.env.NODE_ENV;

export const connectionErrorThresholdMs = {
  online: 10000,
  offline: 10000,
};

export const googleRecaptchaSiteKeyInvisible = environmentConfig.GOOGLE_RECAPTCHA_SITE_KEY_INVISIBLE;
export const googleRecaptchaSiteKeyCheckboxChallenge = environmentConfig.GOOGLE_RECAPTCHA_SITE_KEY_CHECKBOX_CHALLENGE;
export const passwordChangeAfterResetRecaptchaAction = 'SET_NEW_PASSWORD';
export const requestPasswordResetRecaptchaAction = 'REQUEST_PASSWORD_RESET';
export const cookieBotDomainGroupId = environmentConfig.COOKIEBOT_DOMAIN_GROUP_ID;
export const cdnUrl = environmentConfig.REACT_APP_CDN_URL;
export const restoreScrollCarsToBuyKey = 'restoreScrollCarsToBuy';
export const restoreScrollCarsFavouritesKey = 'restoreScrollFavourites';
export const datadogLogsEnabled = environmentConfig.DATADOG_LOGS;
export const growthbookClientKey = environmentConfig.GROWTHBOOK_CLIENT_KEY;
export const growthbookDevMode = environmentConfig.GROWTHBOOK_DEV_MODE === 'enabled';
